/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Avatar, Button, Divider, Select, message } from 'antd';
import redAlarmIcon from '../../images/red.png';
import Typography from 'antd/es/typography/Typography';
import { useAuth } from '../Context';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

export default function DataLoss (props) {
	
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const navigate = useNavigate();
	const regionId = props.regionId;
	const {pidsRegionData, getPidsRegion } = useAuth();

	const [pidsId, setPidsId] = useState();
	const [dailyLoss, setDailyLoss] = useState();
	const [hourlyLoss, sethourlyLoss] = useState();
	let pidsList = [];
	let tempPidsId;

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getPidsRegion(authtoken, regionId);
	},[authtoken, regionId]);

	pidsRegionData && pidsRegionData.map((item, index) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase()
		});
		if (index === 0) {
			tempPidsId = item.id;
		}

	});

	pidsList.sort((a,b) => { return a.value - b.value; });
	
	useEffect(() => {
		setPidsId(tempPidsId);
	},[tempPidsId, regionId]);


	useEffect(() => {
		if(pidsId) {

			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/fibreshotmonitoring-new?pidsinfoId=${pidsId}&date=${props.date.split('/').reverse().join('-')}`,
				timeout: timeout,
				headers: { 
					Authorization:`Bearer ${authtoken}`
				}
			};

			axios.request(config)
				.then((response) => {
					if(response.data.data.length > 0) {
						response.data.data.map((item) => {
							setDailyLoss(parseFloat(item.daily));
							sethourlyLoss(item.hourly);
						});
					} else {
						setDailyLoss();
						sethourlyLoss();
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});

		}
	},[pidsId]);

	const handlePids = (value) => {
		setPidsId(value);
	};

	const date = new Date();
	let currentHour = date.getHours();
	if (currentHour == 0) {
		currentHour = 23;
	} else {
		currentHour -= 1;
	}

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return(
		<>
			<Select
				style={{ width: '200px'}}
				placeholder="Select Pids"
				optionFilterProp="children"
				options={pidsList}
				onSelect={handlePids}
				value={pidsId}
				// defaultValue={pidsList[0]}
				showSearch
				filterOption={filterOption}
			/>
			<br />
			<Divider />
			<Button style={{ display : 'contents'}} onClick={() => { navigate(('/availability-details'), { state : { date : props.date, pidsId : pidsId, hour : currentHour}});}}>
				<Avatar shape="square" size={40} icon="user" src={redAlarmIcon} />
				<Typography>{langdata && langdata ? langdata.CURRENTDATALOSS: 'CURRENT DATA LOSS'}</Typography>
				<Typography><b>{hourlyLoss &&  hourlyLoss[currentHour] ? hourlyLoss[currentHour].toFixed(2) : 0}%</b></Typography>
				<Divider />
				<Avatar shape="square" size={40} icon="user" src={redAlarmIcon} />
				<Typography>{langdata && langdata ? langdata.AVERAGEDATALOSS: 'AVERAGE DATA LOSS'}</Typography>
				<Typography><b>{dailyLoss ? dailyLoss.toFixed(2) : 0}%</b></Typography>
			</Button>
			<br />
		</>
	);
}