/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/react-in-jsx-scope */

import { Alert, Avatar, Button, Col, Divider, Modal, Row, Space, Typography, message } from 'antd';
import { useEffect, useReducer, useState } from 'react';
import { useAuth } from '../Context';
import axios from 'axios';
import dashboarddatareducer from './reducer';
import { PIDS_STATUS, PIDS_STATUS_ERROR, PIDS_STATUS_SUCCESS } from './constants';
import up from '../../images/GreenArrowUp.png';
import down from '../../images/RedArrowDown.png';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

export default function EdgeDown (props) {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const {getPids, pidsData } = useAuth();
	const initalState = { pidsStatus : [], pending: false, error: false };
	const [data, dispatch] = useReducer(dashboarddatareducer, initalState);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envstrgeedge = process.env.REACT_APP_EDGE == undefined || process.env.REACT_APP_EDGE == 'undefined' ? 'false' : process.env.REACT_APP_EDGE;
	const isEdge = localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_EDGE`) == 'null' ? envstrgeedge : localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_EDGE`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	useEffect(() => {
		getPids(authtoken);
		dispatch({ type : PIDS_STATUS });
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pidsstatus-new?date=${props.date.split('/').reverse().join('-')}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if ( response.status === 200) {
					dispatch({ type : PIDS_STATUS_SUCCESS , payload : response.data});
				} else {
					dispatch ({ type : PIDS_STATUS_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : PIDS_STATUS_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	},[authtoken]);

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	let status = '';
	let downpidcount = 0;
	let uppidcount = 0;
	// let nopidscount = 0;
	// let totalpids = 0;
	let edgedown = 0;
	const pidsArray = [];
	pidsData && pidsData.map((item) => {
		if(item.productionStatus.toLowerCase() === 'c') {
			 status = 'Central';
		} else if(item.productionStatus.toLowerCase() === 'e') {
			status = 'Edge';
		}else if(item.productionStatus.toLowerCase() === 'm') {
			status = 'Mainatainance';
		} else if(item.productionStatus.toLowerCase() === 'n') {
			status = 'No Pids';
		}
		if(item.productionStatus.toLowerCase() == 'c' || item.productionStatus.toLowerCase() == 'e'){
			const checkExist = data.pidsStatus.find((oc) => oc.pidsinfoId == item.id);
			if(checkExist === undefined){
				edgedown = 0;
			} else {
				
				if(checkExist){
					if(checkExist.status){
						// totalpids++;
						uppidcount++;
						if (status == 'Central') {
							edgedown++;
						}
						pidsArray.push({ pidsname: item.name, availability: true, status: item.productionStatus.toLowerCase(), status_label: status });
					} else {
						// totalpids++;
						downpidcount++;
						edgedown++;
						pidsArray.push({ pidsname: item.name, availability: false, status: item.productionStatus.toLowerCase(), status_label: status });
					}
				} 
				else {
					// totalpids++;
					downpidcount++;
					edgedown++;
					pidsArray.push({ pidsname: item.name, availability: false, status: item.productionStatus.toLowerCase(), status_label: status });
				}
			}
		} 
		else if (item.productionStatus.toLowerCase() == 'm') {
			// totalpids++;
			downpidcount++;
			edgedown++;
			pidsArray.push({ pidsname: item.name, availability: false, status: item.productionStatus.toLowerCase(), status_label: status });
		} else if (item.productionStatus.toLowerCase() == 'n') {
			// nopidscount++;
			pidsArray.push({ pidsname: item.name, availability: false, status: item.productionStatus.toLowerCase(), status_label: status });
		}
	});
    
	return(
		<>
			<Row style={{
				cursor: 'pointer',
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center',
			}} span={24} title={langdata && langdata ? langdata.PIDSAVAILABILITY :'PIDS AVAILABILITY'}>
				<Col span={9} >
					{edgedown !== 0 && isEdge=='true' ?
						<Alert message={`${edgedown} EDGE DOWN`} type="error" showIcon style={{ marginTop: '-5px', fontWeight: 'bold' }} onClick={showModal}/>
						: null }
				</Col>
				<Space>&nbsp;&nbsp;</Space>
				<Col span={14} style={{ display: 'flex', alignItems: 'center', marginLeft : 'auto'}}>
					<Typography style={{ fontSize : '25px'}} onClick={showModal}><b style={{fontSize:'25px'}}>{uppidcount}</b></Typography>
					<Space>

						<Avatar onClick={showModal} shape="square" size={25} icon="user" src={up}  style={{ marginTop: '-6px', marginRight: '15px' }} />
					</Space>

					<Typography style={{ fontSize : '25px'}} onClick={showModal}><b style={{fontSize:'25px'}}>{downpidcount}</b></Typography>
					<Space>
						<Avatar
							shape="square"
							size={25}
							onClick={showModal}
							icon="user"
							src={down}
							style={{
								marginTop: '-5px',
							}}
						/>
					</Space>
					<Space>&nbsp;&nbsp;</Space>
					<Space>
						<Typography style={{ cursor: 'default', fontSize : '20px', marginTop:'-1px'}}><b style={{fontSize:'25px'}}>{props.channelCount}</b>&nbsp;{props.channelCount == 1 ? `${langdata && langdata ? langdata.Channel : 'Channel'}` : `${langdata && langdata ? langdata.Channels : 'Channels'}` }</Typography>
					</Space>
					<Space>&nbsp;&nbsp;</Space>
				</Col>
			</Row>
			<Modal title={langdata && langdata ? langdata.PIDSAVAILABILITY :'PIDS AVAILABILITY'} open={isModalOpen}
				onCancel={handleCancel}
			 	footer={[
					<Button type="primary" onClick={handleOk}>
						{langdata && langdata ? langdata.CLOSE :'CLOSE'}
					</Button>,
				]}
			 >
				<Divider />
				<Row span={24}>
					<Col span={12}>
						<Typography><b>{langdata && langdata ? langdata.EDGEUP :'EDGE - UP'}</b><Avatar shape="square" size={15} icon="user" src={up}  style={{ marginTop: '-6px', marginRight: '15px' }} /></Typography>
						{pidsArray.map((pidV, i) => {
							if (pidV.availability && pidV.status != 'n' && pidV.status_label == 'Edge') {
								return (<div key={i}>{pidV.pidsname}</div>);
							}
						})}

					</Col>
					<Col span={12}>
						<Typography><b>{langdata && langdata ? langdata.UPONCENTRAL :'UP ON CENTRAL'}</b><Avatar shape="square" size={15} icon="user" src={up}  style={{ marginTop: '-6px', marginRight: '15px' }} /></Typography>
						{pidsArray.map((pidV, i) => {
							if (pidV.availability && pidV.status != 'n' && pidV.status_label == 'Central') {
								return (<div key={i}>{pidV.pidsname}</div>);
							}
						})}
					</Col>  
				</Row>
				<Row span={24}>
					<Divider/>
				</Row>
				<Row span={24}>
					<Col span={24}>
						<Typography><b>{langdata && langdata ? langdata.DOWN :'DOWN'}</b>						
							<Avatar
								shape="square"
								size={15}
								icon="user"
								src={down}
								style={{
									marginTop: '-3px',
								}}
							/></Typography>
						{pidsArray.map((pidV, i) => {
							if ((!pidV.availability) && pidV.status != 'n') {
								return (<div key={i}>{pidV.pidsname} ({pidV.status_label})</div>);
							}
						})}
					</Col>
				</Row>
			</Modal>
		</>
	);
}