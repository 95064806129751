/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

import { Card } from 'antd';
import useResizeObserver from 'use-resize-observer';
import Chart from 'react-apexcharts';
import { useEffect, useState } from 'react';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

export default function HourChart (props) {
	const { ref, width } = useResizeObserver();

	const [redGraphHour, setRedGraphHour] = useState([]);
	const [redGraphCount, setRedGraphCount] = useState([]);
	const [ambergraphCount, setAmbergraphCount] = useState([]);
	const [greengraphCount, setGreengrpahCount] = useState([]);
	const [cleargraphCount, setCleargraphCount] = useState([]);

	// let redGraphCount = [];
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

    
	let minChartWidth = parseInt(100/70).toFixed(2);
 
	minChartWidth = (minChartWidth < 1) ? 1 : minChartWidth;
	
	// let redGraphCountList = [];
	// let redGraphHourList = [];
	// let yellowGraphCountList = [];
	// let greenGraphCountList = [];
	
	useEffect(() => {
		if(props.data) {
			// setRedGraphCount([]);
			// setRedGraphHour([]);
			// setAmbergraphCount([]);
			// setGreengrpahCount([]);
			// let redGraphCountList = [];
			let redGraphHourList = [];
			let initialStateArrayRed = [];
			let initialStateArrayAmber = [];
			let initialStateArrayGreen = [];
			let initialStateArrayClear = [];
			// let yellowGraphCountList = [];
			// let greenGraphCountList = [];
			props.data && props.data.map((item, index) => {

				const strAscending = [...item.alarmGraph].sort((a, b) =>
					a.hour > b.hour ? 1 : -1,
				);

				

				strAscending && strAscending.map((graphData) => {
					if (graphData.alarmSeverity === 3){
						if(index === 0){
							initialStateArrayRed.push(graphData.count);
							setRedGraphCount(initialStateArrayRed);
							redGraphHourList[graphData.hour] = graphData.hour;
						}
						else{
							initialStateArrayRed && initialStateArrayRed.map((innerItem, index1) => {
								let stateArray = [];
								if(index1 === graphData.hour) {
									stateArray = initialStateArrayRed;
									stateArray[index1] =  innerItem + graphData.count;
									setRedGraphCount(stateArray);
								}
							});
						}
					} else if (graphData.alarmSeverity === 2) {
						if(index === 0) {
							initialStateArrayAmber.push(graphData.count);
							setAmbergraphCount(initialStateArrayAmber);
						} else {
							initialStateArrayAmber && initialStateArrayAmber.map((innerItem, index1) => {
								let stateArray = [];
								if(index1 === graphData.hour) {
									stateArray = initialStateArrayAmber;
									stateArray[index1] =  innerItem + graphData.count;
									setAmbergraphCount(stateArray);

								}
							});
						}
					} else if (graphData.alarmSeverity === 1){
						if(index === 0) {
							initialStateArrayGreen.push(graphData.count);
							setGreengrpahCount(initialStateArrayGreen);
						} else {
							initialStateArrayGreen && initialStateArrayGreen.map((innerItem, index1) => {
								let stateArray = [];
								if(index1 === graphData.hour) {
									stateArray = initialStateArrayGreen;
									stateArray[index1] =  innerItem + graphData.count;
									setGreengrpahCount(stateArray);

								}
							});
						}
					}
					else{
						if(index === 0) {
							initialStateArrayClear.push(graphData.count);
							setCleargraphCount(initialStateArrayClear);
						} else {
							initialStateArrayClear && initialStateArrayClear.map((innerItem, index1) => {
								let stateArray = [];
								if(index1 === graphData.hour) {
									stateArray = initialStateArrayClear;
									stateArray[index1] =  innerItem + graphData.count;
									setCleargraphCount(stateArray);

								}
							});
						}
					}
				});
				// setRedGraphHour(redGraphHourList);
				// setAmbergraphCount(yellowGraphCountList);
				// setGreengrpahCount(greenGraphCountList);
			});
		}
		else {
			setRedGraphCount([]);
			setRedGraphHour([]);
			setAmbergraphCount([]);
			setGreengrpahCount([]);
			setCleargraphCount([]);
		}
	},[props.data]);
	
	let series = [{
		name: `${langdata && langdata ? langdata.Red : 'Red'}`,
		data: redGraphCount && redGraphCount
	},
	{
		name: `${langdata && langdata ? langdata.Amber : 'Amber'}`,
		data: ambergraphCount && ambergraphCount
	},
	{
		name: `${langdata && langdata ? langdata.Green : 'Green'}`, 
		data: greengraphCount && greengraphCount
	},
	{
		name: `${langdata && langdata ? langdata.Clear : 'Clear'}`, 
		data: cleargraphCount && cleargraphCount
	}];
	
	let options = {
		dataLabels: {
			enabled: false
		},
		chart: {
			toolbar: {
				show: false
			},
			zoom: {
				enabled: false,
			},
		},
        
		legend: {
			show: true,
			position: 'top',
			horizontalAlign: 'center',
			onItemClick: {
				toggleDataSeries: true
			},
			onItemHover: {
				highlightDataSeries: true
			}
		},
		yaxis: {
			title: {
				text: `${langdata && langdata ? langdata.Alarms : 'Alarms'}`
			}
		},
		xaxis: {
			title: {
				text: `${langdata && langdata ? langdata.Hours : 'Hours'}`
			},
			categories: redGraphHour,
			labels: {
				show: true,
				showDuplicates: true,
				formatter: function (val) {
					return val;
				}
			}
		},
		colors: ['#fc0303', '#ffbf00', '#008000', '#e963f7'],
		markers: {
			size: 5,
			shape: 'square'
		},
		stroke: {
			curve: 'smooth',
			width: 2
		}
	};

	return (
		<div ref={ref}>
			<Card className="card-Shadow-dashboard" style={{overflow: 'hidden'}}>
				<Chart height={300} width={(width - 50) * minChartWidth} options={options} series={series} type="line" />
			</Card>
		</div>
	);
}